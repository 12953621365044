import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";

export const UiUxDesignerJsonLd = () => {
  const jobPostingJson = {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    datePosted: "2021-01-01",
    validThrough: "2021-12-30",
    baseSalary: {
      "@type": "MonetaryAmount",
      minValue: "$",
      maxValue: "$",
      currency: "USD"
    },
    description:
      "UX Design Studio: Improve your App’s Usability, Retention Rate, Conversion & realize your Business Goals.",
    educationRequirements: ["no requirements"],
    employmentType: "Full-Time - 100% Remote",
    incentiveCompensation:
      "Performance-based annual bonus plan, project-completion bonuses",
    industry: "Computer Software",
    hiringOrganization: "Adam Fard Studio",
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Neue Schönhauser Straße 3",
        addressLocality: "Berlin",
        addressRegion: "BE",
        postalCode: "10178"
      }
    },
    applicationContact: "contact@adamfard.com",
    occupationalCategory: ["Marketing Specialist"],
    qualifications: [
      "3+ year of UX or Product design hands-on experience",
      "Experience designing data heavy / B2B products",
      "English C1",
      "Proficiency in Figma",
      "Being a problem-solver. You love finding out you're wrong",
      "You consume large amounts of qualitative and quantitative data to constantly refine your assumptions.",
      "Communicating about any questions/problems/ideas/suggestions, asking for feedback and sharing feedback easily.",
      "Being in charge of tasks, to know and tell others about ability or disability to fit the time limit"
    ],
    responsibilities: [
      "Conduct UX Audit & Heuristic Evaluation",
      "Define concepts according to tasks and business requirements",
      "Create UX Strategies",
      "Lo-fi sketches",
      "Hi-fi Wireframes",
      "Clickable prototypes",
      "UI Concepts & Mood boards",
      "Hi-fi UI",
      "GUI Library",
      "Specs for Devs"
    ],
    jobBenefits: [
      "100% Remote",
      "No micromanagement",
      "Balanced workload",
      "Access to our Talent Development Program"
    ],
    salaryCurrency: "USD",
    skills: "not required",
    specialCommitments: ["English C1"],
    title: "Join Our Team!",
    url: "https://adamfard.com/ui-ux-designer",
    image:
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp"
  };

  const webpageJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://adamfard.com/ui-ux-designer/#Webpage",
    url: "https://adamfard.com/ui-ux-designer/",
    headline: "Product Designer / UI UX designer Full-Time - 100% Remote",
    primaryImageOfPage: [
      "https://www.datocms-assets.com/16499/1583861795-remote-ui-ux-designer-job.png"
    ],
    lastReviewed: "2021/01/17",
    relatedLink: ["https://en.wikipedia.org/wiki/User_interface_design"],
    significantLink: "https://adamfard.com/contact-us",
    description:
      "UX Design Studio: Improve your Apps Usability, Retention Rate, Conversion & realize your Business Goals.",
    image:
      "https://www.datocms-assets.com/16499/1593276692-ux-design-process-design-agency.png?fm=webp&w=800",
    sameAs: ["https://en.wikipedia.org/wiki/User_interface"],
    about: [
      {
        "@type": "thing",
        name: "User interface design",
        sameAs: ["https://en.wikipedia.org/wiki/User_interface_design"]
      },
      {
        "@type": "thing",
        name: "Usability testing",
        sameAs: "https://en.wikipedia.org/wiki/Usability_testing"
      },
      {
        "@type": "thing",
        name: "User experience design",
        sameAs: "https://en.wikipedia.org/wiki/User_experience_design"
      }
    ]
  };

  const breadCrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    numberOfitems: "2",
    description:
      "UX Design Studio: Improve your App’s Usability, Retention Rate, Conversion & realize your Business Goals.",
    disambiguatingDescription:
      "Improve your app's usability, retention rate, and conversion rate while achieving your business goals with UX Design Studio.",
    mainEntityOfPage: "https://adamfard.com/ui-ux-designer/",
    image:
      "https://www.datocms-assets.com/16499/1583861795-remote-ui-ux-designer-job.png",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: { "@id": "https://adamfard.com/", name: "Adam Fard" }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": "https://adamfard.com/ui-ux-designer/",
          name: "UI UX Designer"
        }
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(jobPostingJson)}
      </script>
      <script type="application/ld+json">{JSON.stringify(webpageJson)}</script>
      <script type="application/ld+json">
        {JSON.stringify(breadCrumbJson)}
      </script>
    </Helmet>
  );
};
